<template>
  <div>
    <div class="view pa24 mr mb20">
      <span style="color: #8d97ad; font-size: 14px"
      >发布流程：1.选择商品分类 ------ 2.输入商品基本信息 ------
        3.输入商品详情 ------ 4.发布商品</span
      >
    </div>

    <el-form
        ref="form" :model="form" :rules="rules" label-width="auto" class="view pa24" label-position="left"
    >
      <p class="cA2 fwbold fs8 mb20">1.选择商品类型</p>
      <el-form-item prop="goodstypeId" label="商品分类">
        <el-select v-model="form.goodstypeId" placeholder="请选择商品类型">
          <template v-for="(item, index) in goodsType">
            <el-option
                :label="item.name"
                :value="item.goodstypeId"
                :key="index"
            />
          </template>
        </el-select>
        <el-button type="primary" class="ma ml10" @click="open"
        >添加分类</el-button
        >
      </el-form-item>
      <hr
          style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 20px 0;
        "
      />
      <p class="cA2 fwbold fs8 mb20">2.输入商品基本信息</p>
      <el-form-item prop="goodsName" label="商品标题">
        <el-input
            v-model="form.goodsName"
            style="width: 350px"
            placeholder="请选择商品名称"
            maxlength="30"
            show-word-limit
        ></el-input>
      </el-form-item>

      <el-form-item prop="goodsGG" label="商品规格">
        <el-select v-model="form.goodsGG" placeholder="请选择商品类型" style="width: 350px" @change="change">
          <template v-for="(item, index) in goodsGG">
            <el-option
                :label="item.title"
                :value="item.id"
                :key="index"
            />
          </template>
        </el-select>
      </el-form-item>

      <el-form-item prop="stockNum" label="商品库存" v-if="form.goodsGG == 1">
        <el-input
            v-model="form.stockNum"
            oninput="value=value.replace(/[^\d]/g,'')"
            type="number"
            style="width: 350px"
            placeholder="请选择商品库存"
            show-word-limit
            maxlength="10"
        ></el-input>
      </el-form-item>

      <el-form-item prop="price" label="商品价格" v-if="form.goodsGG == 1">
        <el-input
            v-model="form.price"
            type="number"
            style="width: 350px"
            placeholder="请选择商品价格"
            show-word-limit
            maxlength="10"
        ></el-input>
      </el-form-item>

      <el-form-item label="商品规格" v-if="form.goodsGG == 2">
        <div class="ggNew_wrap" ref="addEl">
          <div class="gg_item" v-for="(v,k) in prodSize" :key="k">
            <div class="gg_item_del">
              <img src="https://jkt-crm-pc.oss-cn-huhehaote.aliyuncs.com/xcx/close.png" @click="delSize(k)" alt=""
                   class="gg_item_del_img"/>
            </div>

            <div>
              <div class="ggdx">
                <span>*</span>
                <span>规格项</span>
                <span>例如颜色、尺码、口味等</span>
              </div>
              <div class="ggdx_zhi">
                <el-input
                    @input="inputChange"
                    v-model="v.specName"
                    style="width: 350px"
                    placeholder="请输入规格项名称"
                ></el-input>
              </div>
            </div>

            <div class="">
              <div class="ggdx">
                <span>*</span>
                <span>规格值</span>
                <span>例如规格项输入颜色，规格值添加红色、白色、蓝色等</span>
              </div>
              <div class="gg_list">
                <div class="gg_add" v-for="(item,index) in v.list" :key="index" @click="toUpdate(k,index,item)">
                  <span v-if="item.text0">{{ item.text0 }}</span>
                  <span v-if="item.text1">{{ item.text1 }}</span>
                  <span v-if="item.text2">{{ item.text2 }}</span>
                  <span v-if="item.text3">{{ item.text3 }}</span>

                  <img src="https://jkt-crm-pc.oss-cn-huhehaote.aliyuncs.com/xcx/close.png"
                       @click="delGG(k,index)" alt="" class="gg_add_del"/>
                </div>
                <p class="gg_add" @click="addSize(k)">+ 规格值</p>
              </div>
            </div>
          </div>
          <span class="hover_pointer moreGG" @click="addSpec()">+ 添加规格项</span>
        </div>
      </el-form-item>

      <el-form-item label="商品明细" v-if="gglist.length">
        <div class="ggNew_wrap1">
          <div class="theadList">
            <div class="theadItem" style="width:25%" v-for="(item,index) in titleItem" :key="index"> <span
                class="xing"
                v-if="index==titleItem.length-1 || index==titleItem.length-2">*</span> {{ item }}
            </div>
          </div>
          <div class="" v-for="(item,index) in gglist" :key="index">
            <div class="tbodyList">
              <div class="tbodyTitle" style="width:25%" v-if="item.text0">
                <span>{{ item.text0 }}</span>
              </div>
              <div class="tbodyTitle" style="width:25%" v-if="item.text1">
                <span>{{ item.text1 }}</span>
              </div>
              <div class="tbodyTitle" style="width:25%" v-if="item.text2">
                <span>{{ item.text2 }}</span>
              </div>
              <div class="tbodyTitle" style="width:25%" v-if="item.text3">
                <span>{{ item.text3 }}</span>
              </div>
              <div class="tbodyTitle" style="width:25%">
                <div style="position: relative;width: 40px;height: 40px">
                  <!--                  :class="item.img ? 'disUoloadSty':''"-->
                  <el-upload
                      :action="uploadUrl"
                      :class="item.img ? 'disUoloadSty':''"
                      list-type="picture-card"
                      :before-upload="beforeAvatarUploadImg"
                      :limit="1"
                      :on-success="(response, file, fileList) => {handleSuccess(response, file, fileList,index)}"
                      :on-remove="(file, fileList) => {handleRemove3(file, fileList,index)}"
                      :file-list="item.fileList"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </div>
              </div>
              <div class="tbodyTitle" style="width:25%">
                <el-input
                    class="tbodyInput"
                    @input="changeValue"
                    v-model="item.price"
                    placeholder="请输入"
                ></el-input>
              </div>
              <div class="tbodyTitle" style="width:25%">
                <el-input
                    v-model="item.inventory"
                    @input="changeValue"
                    placeholder="请输入"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
      </el-form-item>

      <el-form-item prop="goodPhoto" label="商品图片">
        <p class="ma mb10" style="color: rgb(150, 150, 150)">
          上传图片的最佳尺寸：800像素*800像素，其他尺寸会影响页效果，格式png，jpeg，jpg。大小不超过4M，商品图片一共可以上传5张，默认第一张为主图封面
        </p>
        <div style="display: flex">
          <el-upload
              :action="uploadUrl"
              :class="{
              disabled: uploadDisabled,
            }"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :on-success="goodsUpload"
              :file-list="form.goodPhoto"
              :limit="5"
              multiple
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog
              :visible.sync="dialogVisible"
              append-to-body
              :modal-append-to-body="false"
          >
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </div>
      </el-form-item>
      <hr
          style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 20px 0;
        "
      />
      <el-form-item prop="coverImg"  label="分享图片">
        <p class="ma mb10" style="color: rgb(150, 150, 150)">
          上传图片及支持png/jpg 。显示图片长宽比为5:4
        </p>
        <div>
          <el-upload
              :action="uploadUrl"
              :class="{
              disabled: uploadDisabled2,
            }"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove2"
              :on-success="shareUpload"
              :file-list="form.coverImg"
              :limit="1"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog
              :visible.sync="dialogVisible"
              append-to-body
              :modal-append-to-body="false"
          >
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </div>
      </el-form-item>
      <hr
          style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 20px 0;
        "
      />
      <el-form-item label="是否参加分销" label-width="130px">
        <el-radio-group v-model="form.isDistribution">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="是否发布到组织商城" label-width="150px">
        <el-radio-group v-model="form.isPublic">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="选择类型" label-width="150px" v-if="form.isPublic">
        <el-radio-group v-model="form.type">
          <el-radio :label="item.goodstypeId" v-for="(item,index) in goodsTypeSC" :key="index">{{item.name}}</el-radio>
        </el-radio-group>
      </el-form-item>

      <p class="cA2 fwbold fs8 mb20">3.支付方式</p>
      <el-form-item label="支付方式">
        <el-radio-group v-model="form.ispayment">
          <el-radio :label="1">线上支付</el-radio>
          <el-radio :label="0">其他</el-radio>
        </el-radio-group>
      </el-form-item>

      <p class="cA2 fwbold fs8 mb20">4.输入商品详情</p>
      <el-form-item label-width="0px">
        <p class="ma mb10">商品详情</p>
        <!-- //富文本 -->
        <quill-editor
            style="height: 100%"
            v-model="form.goodsDetails"
            ref="myQuillEditor"
            :options="editorOption"
            @ready="ready($event)"
        />
      </el-form-item>

      <el-form-item label-width="0px">
        <el-button
            type="primary"
            size="medium"
            @click="addGood"
        >发布</el-button
        >

        <el-button size="medium" @click="yulan">预览</el-button>
      </el-form-item>
      <prevGoods :isShow="isShow"
                 :prod_imgs="form.goodPhoto"
                 :prod-size="goodSpecRequestList"
                 :edit_html="form.goodsDetails"
                 @close="prodClose"
      ></prevGoods>
    </el-form>

    <el-dialog
        title="规格值"
        :visible.sync="centerDialogVisible_gg"
        :modal-append-to-body="false"
        width="50%"
        center
        @click="toClose"
    >
      <div>
        <el-input v-model="ggNum" style="width: 100%" placeholder="请输入规格值"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible_gg = false">取 消</el-button>
        <el-button type="primary" @click="addGGZ">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// @ts-ignore
import {
  getGoodsTypeInfo,
  addGoodsType,
  getGoodsInfo,
  addGoods,
  selectGoodsTypeByCid
} from "@/api/coupon.js";
import prevGoods from './components/prevGoods'
import { lineHeightStyle } from "@/utils/lineHeight.js";
import { quillEditor, Quill } from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = '请填写商品内容'

export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (this.form.goodSpecModelList[0] ||  this.form.goodSpecRequestList[0]  ) {
        callback();
      } else {
        callback(new Error("请输入商品属性"));
      }
    };
    var valiGoodPhoto = (rule, value, callback) => { // 图片验证
      if (this.form.goodPhoto.length == 0) {
        callback(new Error("请上传商品图片"));
      } else {
        callback();
      }
    };
    var valiCoverImg = (rule, value, callback) => { // 图片验证
      if (this.form.coverImg.length == 0) {
        callback(new Error("请上传分享图片"));
      } else {
        callback();
      }
    };
    return {
      editorOption: quillConfig,
      isShow: false,
      form: {
        type:"",
        goodsName: "", //商品名称
        stockNum: "", //库存
        price: "",  //价格
        goodstypeId: "", //类型ID
        goodsDetails: "", //商品详情
        ispayment: 1, //支付方式
        isDistribution: 0, //分销售
        isPublic: false, //上架
        goodPhoto: [], //商品图片
        coverImg: [],
        //商品规格
        specAttribute:'',
        goodSpecRequestList: [
          {
            specName: "", //规格名称
            list: [
              {
                specAttribute: "", //规格属性（价格）
                specNum: "1", //规格数量
                price: '', //价格除100
                requiredSelect:false
              },
            ],
          },
        ],
        goodSpecModelList:[],
        goodsGG: '',  //商品规格
      },
      rules: {
        goodstypeId: [{ required: true, message: "请选择分类！" }],
        goodsName: [{required: true, message: "请输入产品标题！", trigger: "change",},],
        stockNum: [{ required: true, message: "请输入库存", trigger: "change"}],
        price: [{required: true, message: "请输入价格", trigger: "change"}],
        goodSpecModelList: [{ required: true, validator: validatePass2 , trigger: "change" }],
        goodsDetails: [
          { required: true, message: "请输入描述", trigger: "change" },
        ],
        goodPhoto: [{ required: true, validator: valiGoodPhoto, trigger: "blur"  }],
        coverImg: [{ required: true, validator: valiCoverImg , trigger: "blur" }],
        goodsGG: [{required: true, message: "请选择商品规格！"}],
      },
      goodsType: [], //分类渲染
      goodsTypeSC:[],
      uploadUrl: this.$store.state.uploadingUrl,
      ossUrl: this.$store.state.ossUrl,
      dialogImageUrl: "",
      dialogVisible: false,
      indexs: "",
      goodsGG: [
        {
          id: 1,
          title: '单规格',
        },
        {
          id: 2,
          title: '多规格'
        }
      ],
      prodSize: [], //产品规格
      ggIndex: 0,
      ggIndex1: 0,
      centerDialogVisible_gg: false,
      ggNum: '',
      gglist: [],
      titleItem: [],
      fileList: [],
      goodSpecRequestList:[],
      ifUpdate:false
    };

  },
  components: {
    prevGoods,
    quillEditor
  },
  computed: {
    uploadDisabled() {
      return this.form.goodPhoto.length >= 5;
    },
    uploadDisabled2() {
      return this.form.coverImg && this.form.coverImg.length >= 1;
    },
  },
  mounted() {
    this.getTypeList()
  },
  async created() {
    this.goodsId = this.$route.query.id;
    if (this.$route.query.id) {
      this.getGoodsInfo(this.$route.query.id);
    }
    let ajax = {
      pageNum:1,
      pageSize:999,
      companyId: localStorage.getItem("companyId"),
    }
    const datas = await selectGoodsTypeByCid(ajax);
    this.goodsType = datas.data.pageInfo.list;
  },

  methods: {
    ready() {
      Quill.register({ 'formats/lineHeight': lineHeightStyle }, true);
    },
    inputChange(){
      let _this = this
      let titleItem = []
      _this.prodSize.map((item, index) => {
        titleItem.push(item.specName)
      })
      let shenyu = ['主图', '价格(元)', '库存']
      _this.titleItem = titleItem.concat(shenyu)
    },
    changeValue(){
      this.$forceUpdate()
    },
    change(e){
      this.form.goodsGG = e
      if ( e == 1 ) {
        this.prodSize = []
        this.gglist = []
      }
      this.$forceUpdate()
    },
    async getGoodsInfo(id){
      let _this = this
      const result = await getGoodsInfo({ goodsId: id });
      let results = JSON.parse(result.data.pageInfo).list[0];
      if (results.goodPhoto ) {
        results.goodPhoto = results.goodPhoto.split(",").map((item, index) => {
          return {
            url: item,
            uid: index,
          };
        });
      }
      if (results.coverImg && results.coverImg.length !== 2 && results.coverImg.length !== 0) {
        results.coverImg = [{ url: results.coverImg, uid: 1 }];
      } else {
        results.coverImg = [];
      }
      _this.form = results;
      _this.form.type = results.publicTypeId
      if ( _this.form.specType == 'ONE' ) {
        _this.form.goodsGG = 1

        _this.form.stockNum = _this.form.goodSpecModelList[0].specNum;
        _this.form.price = (_this.form.goodSpecModelList[0].price/100).toFixed(2);

      }else{
        _this.form.goodsGG = 2
        if (_this.form.goodSpecModelList.length || _this.form.goodSpecModelList) {
          // _this.form.goodSpecModelList.map(val => {
          //   if (val.specName) {
          //     _this.prodSize = [{
          //       specName: '',
          //       list: []
          //     }]
          //     _this.prodSize[0]['specName'] = val.specName
          //   }
          //   if (val.specName2) {
          //     _this.prodSize = [{
          //       specName: '',
          //       list: []
          //     }, {
          //       specName: '',
          //       list: []
          //     }]
          //     _this.prodSize[0]['specName'] = val.specName
          //     _this.prodSize[1]['specName'] = val.specName2
          //   }
          //   if (val.specName3) {
          //     _this.prodSize = [{
          //       specName: '',
          //       list: []
          //     }, {
          //       specName: '',
          //       list: []
          //     }, {
          //       specName: '',
          //       list: []
          //     }]
          //     _this.prodSize[0]['specName'] = val.specName
          //     _this.prodSize[1]['specName'] = val.specName2
          //     _this.prodSize[2]['specName'] = val.specName3
          //   }
          //   if (val.specName4) {
          //     _this.prodSize = [{
          //       specName: '',
          //       list: []
          //     }, {
          //       specName: '',
          //       list: []
          //     }, {
          //       specName: '',
          //       list: []
          //     }, {
          //       specName: '',
          //       list: []
          //     }]
          //     _this.prodSize[0]['specName'] = val.specName
          //     _this.prodSize[1]['specName'] = val.specName2
          //     _this.prodSize[2]['specName'] = val.specName3
          //     _this.prodSize[3]['specName'] = val.specName4
          //   }
          // })
          _this.prodSize = JSON.parse(_this.form.qdJson)
        }

        let titleItem = []
        setTimeout(function() {
          if ( _this.prodSize.length == 1 ) {
            if ( _this.form.goodSpecModelList ) {
              let a = _this.form.goodSpecModelList
              a.map((item1,index1) => {
                for (let i in _this.prodSize[0].list) {
                  if ( _this.prodSize[0].list[i].text0 == item1.specAttribute ) {
                    _this.prodSize[0].list[i].fileList =  [{url:item1.specGoodsImg,uid:index1}]
                    _this.prodSize[0].list[i].id = item1.goodsId
                    _this.prodSize[0].list[i].img = item1.specGoodsImg
                    _this.prodSize[0].list[i].inventory = item1.specNum
                    _this.prodSize[0].list[i].price = (item1.price / 100).toFixed(2)
                  }
                }
              })
            }
          }
          if ( _this.prodSize.length == 2 ) {
            // if ( _this.form.specAttributeList ) {
            //   let a = _this.form.specAttributeList.split(',')
            //   let b = []
            //   a.map((item1,index1) => {
            //     _this.prodSize[0].list.push({text0:item1});
            //   })
            // }
            // if ( _this.form.specAttributeList2 ) {
            //   let a = _this.form.specAttributeList2.split(',')
            //   a.map((item1,index1) => {
            //     _this.prodSize[1].list.push({text1:item1});
            //   })
            // }
          }
          if ( _this.prodSize.length == 3 ) {
            // if ( _this.form.specAttributeList ) {
            //   let a = _this.form.specAttributeList.split(',')
            //   a.map((item1,index1) => {
            //     _this.prodSize[0].list.push({text0:item1});
            //   })
            // }
            // if ( _this.form.specAttributeList2 ) {
            //   let a = _this.form.specAttributeList2.split(',')
            //   a.map((item1,index1) => {
            //     obj['text1'] = item1
            //     _this.prodSize[1].list.push({text1:item1});
            //   })
            // }
            //
            // if ( _this.form.specAttributeList3 ) {
            //   let a = _this.form.specAttributeList2.split(',')
            //   a.map((item1,index1) => {
            //     _this.prodSize[2].list.push({text2:item1});
            //   })
            // }
          }
          if ( _this.prodSize.length == 4 ) {
            // if ( _this.form.specAttributeList ) {
            //   let a = _this.form.specAttributeList.split(',')
            //   a.map((item1,index1) => {
            //     _this.prodSize[0].list.push({text0:item1});
            //   })
            // }
            // if ( _this.form.specAttributeList2 ) {
            //   let a = _this.form.specAttributeList2.split(',')
            //   a.map((item1,index1) => {
            //     _this.prodSize[1].list.push({text1:item1});
            //   })
            // }
            //
            // if ( _this.form.specAttributeList3 ) {
            //   let a = _this.form.specAttributeList2.split(',')
            //   a.map((item1,index1) => {
            //     _this.prodSize[2].list.push({text2:item1});
            //   })
            // }
            // if ( _this.form.specAttributeList4 ) {
            //   let a = _this.form.specAttributeList2.split(',')
            //   a.map((item1,index1) => {
            //     _this.prodSize[3].list.push({text3:item1});
            //   })
            // }
          }
          let a = []
          setTimeout(function(){
            _this.prodSize.map((item, index) => {
              titleItem.push(item.specName)
              a.push(_this.prodSize[index].list)
            })
            let shenyu = ['主图', '价格(元)', '库存']
            _this.titleItem = titleItem.concat(shenyu)
            let gglist = _this.form.goodSpecModelList
            for (let i in gglist) {
              if (gglist[i].specAttribute) {
                gglist[i]['text0'] = gglist[i].specAttribute
              }
              if (gglist[i].specAttribute2) {
                gglist[i]['text1'] = gglist[i].specAttribute2
              }
              if (gglist[i].specAttribute3) {
                gglist[i]['text2'] = gglist[i].specAttribute3
              }
              if (gglist[i].specAttribute4) {
                gglist[i]['text3'] = gglist[i].specAttribute4
              }
              if (gglist[i].price) {
                gglist[i].price = (gglist[i].price / 100).toFixed(2)
              }
              if (gglist[i].specNum) {
                gglist[i].inventory = gglist[i].specNum
              }
              if (gglist[i].specGoodsImg) {
                gglist[i].img = gglist[i].specGoodsImg
                gglist[i].fileList = [{url:gglist[i].specGoodsImg,uid:i}]
              }
            }
            _this.gglist = gglist
            _this.$forceUpdate()
          },300)
        }, 500);
      }
    },
    addSpec: function () {
      if (this.prodSize.length < 4) {
        this.prodSize.push({
          specName: '',
          list: []
        });
      } else {
        this.$message.error('最多添加四个规格项！')
        return
      }
    },
    addSize(index) { //添加规格值
      if (!this.prodSize[index].specName) {
        this.$message.error('请先输入规格项！')
        return
      }
      this.ggNum = ''
      this.centerDialogVisible_gg = true
      this.ggIndex = index
    },
    toUpdate(index,index1,item) { //添加规格值
      if (!this.prodSize[index].specName) {
        this.$message.error('请先输入规格项！')
        return
      }
      this.ggNum = item.text0?item.text0:item.text1?item.text1:item.text2?item.text2:item.text3
      this.centerDialogVisible_gg = true
      this.ggIndex = index
      this.ggIndex1 = index1
      this.ifUpdate = true
    },
    toClose(){
      this.ggIndex = 0
      this.ggIndex1 = 0
      this.ifUpdate = false
    },
    addGGZ() {
      let v = this
      if (!v.ggNum) {
        v.$message.error('请输入规格值！')
        return
      }
      let obj = {
        id: v.ggIndex,
        img: '',
        price: '',
        inventory: '',
        fileList:[]
      };
      if ( v.ifUpdate ) {
        let a = 'text' + v.ggIndex
        v.prodSize[v.ggIndex].list[v.ggIndex1][a] = v.ggNum
      }else {
        obj['text' + v.ggIndex] = v.ggNum;
        v.prodSize[v.ggIndex].list.push(obj);
      }

      let a = []
      let titleItem = []
      if (v.$route.query.id) {
        titleItem = v.titleItem
        for (let i in v.prodSize) {
          a.push(v.prodSize[i].list)
        }
        v.titleItem = titleItem
      }else {
        for (let i in v.prodSize) {
          titleItem.push(v.prodSize[i].specName)
          a.push(v.prodSize[i].list)
        }
        let shenyu = ['主图', '价格(元)', '库存']
        v.titleItem = titleItem.concat(shenyu)
      }
      this.generateGroup(a);
      v.centerDialogVisible_gg = false;
      v.ifUpdate = false
    },

    //循环方式组合
    generateGroup(arr) {
      //初始化结果为第一个数组
      var result = new Array();
      //字符串形式填充数组
      for (var i = 0; i < arr[0].length; i++) {
        result.push(JSON.stringify(arr[0][i]));
      }
      //从下标1开始遍历二维数组
      for (var i = 1; i < arr.length; i++) {
        //使用临时遍历替代结果数组长度(这样做是为了避免下面的循环陷入死循环)
        var size = result.length;
        //根据结果数组的长度进行循环次数,这个数组有多少个成员就要和下一个数组进行组合多少次
        for (var j = 0; j < size; j++) {
          //遍历要进行组合的数组
          for (var k = 0; k < arr[i].length; k++) {
            //把组合的字符串放入到结果数组最后一个成员中
            //这里使用下标0是因为当这个下标0组合完毕之后就没有用了，在下面我们要移除掉这个成员
            //组合下一个json字符串
            var temp = result[0] + "***" + JSON.stringify(arr[i][k]);
            result.push(temp);
          }
          //当第一个成员组合完毕，删除这第一个成员
          result.shift();
        }
      }
      //转换字符串为json对象
      let obj = {} // 新建一个对象
      for (var i = 0; i < result.length; i++) {
        if (result[i].indexOf('***') != -1) {
          let item = result[i].split('***')
          for (var j = 0; j < item.length; j++) {
            obj = {
              ...obj,
              ...JSON.parse(item[j])
            }
          }
        } else {
          let item = JSON.parse(result[i])
          obj = {
            ...obj,
            ...item
          }
        }
        result[i] = obj;
      }
      if (this.$route.query.id) {
        if ( this.prodSize.length == 2 ) {
          for ( let i in result ) {
            for (let j in this.gglist) {
              if ( this.gglist[j].text0 == result[i].text0 && this.gglist[j].text1 == result[i].text1 ) {
                result[i].fileList =  [{url:this.gglist[j].specGoodsImg,uid:j}]
                result[i].id = this.gglist[j].goodsId
                result[i].img = this.gglist[j].specGoodsImg
                result[i].inventory = this.gglist[j].specNum
                result[i].price = this.gglist[j].price
              }
            }
          }
        }
        if ( this.prodSize.length == 3 ) {
          for ( let i in result ) {
            for (let j in this.gglist) {
              if ( this.gglist[j].text0 == result[i].text0 && this.gglist[j].text1 == result[i].text1  && this.gglist[j].text2 == result[i].text2) {
                result[i].fileList =  [{url:this.gglist[j].specGoodsImg,uid:j}]
                result[i].id = this.gglist[j].goodsId
                result[i].img = this.gglist[j].specGoodsImg
                result[i].inventory = this.gglist[j].specNum
                result[i].price = this.gglist[j].price
              }
            }
          }
        }
        if ( this.prodSize.length == 4 ) {
          for ( let i in result ) {
            for (let j in this.gglist) {
              if ( this.gglist[j].text0 == result[i].text0 && this.gglist[j].text1 == result[i].text1 && this.gglist[j].text2 == result[i].text2 &&  this.gglist[j].text3 == result[i].text3) {
                result[i].fileList =  [{url:this.gglist[j].specGoodsImg,uid:j}]
                result[i].id = this.gglist[j].goodsId
                result[i].img = this.gglist[j].specGoodsImg
                result[i].inventory = this.gglist[j].specNum
                result[i].price = this.gglist[j].price
              }
            }
          }
        }
      }
      this.gglist = result
    },
    delSize(index1, index2) {
      if (index2 >= 0) {
        this.prodSize[index1].list.splice(index2, 1);
      } else {
        this.prodSize.splice(index1, 1);
      }
      let a = []
      for (let i in this.prodSize) {
        a.push(this.prodSize[i].list)
      }
      this.generateGroup(a);
    },
    delGG(index1, index2) {
      this.prodSize[index1].list.splice(index2, 1);
      let a = []
      let titleItem = []
      for (let i in this.prodSize) {
        if (this.prodSize[i].list.length) {
          titleItem.push(this.prodSize[i].specName)
          a.push(this.prodSize[i].list)
        }
      }
      let shenyu = ['主图', '价格(元)', '库存']
      this.titleItem = titleItem.concat(shenyu)
      if (a.length) {
        this.generateGroup(a);
      } else {
        this.gglist = []
      }
    },
    async getTypeList(){
      let ajax = {
        pageNum:1,
        pageSize:999,
        associationId: localStorage.getItem("associationId"),
      }
      const datas = await selectGoodsTypeByCid(ajax);
      this.goodsTypeSC = datas.data.pageInfo.list;
    },

    handleRemove(file, fileList) {
      this.form.goodPhoto = fileList;
    },
    handleRemove2(file, fileList) {
      this.form.coverImg = fileList;
    },
    //新增商品
    addGood() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.form.goodsGG == 1) {} else {
            if (this.prodSize.length == 0 || this.prodSize[0].list.length == 0) {
              this.$message.error('请至少输入一种商品类型和规格！')
              return
            }
            let prodSize = JSON.parse(JSON.stringify(this.prodSize))
            let gglist = JSON.parse(JSON.stringify(this.gglist))

            for (var i = 0; i < prodSize.length; i++) {
              if (!prodSize[i].specName) {
                this.$message.error('商品类型不能为空！')
                return
              }
            }
            for (var i = 0; i < gglist.length; i++) {
              if (!gglist[i].inventory) {
                this.$message.error('商品规格值不能为空！')
                return
              }
              if (gglist[i].price == '' || gglist[i].price < 0.01) {
                this.$message.error('商品金额不合法，请重新输入！')
                return
              }
            }
          }

          let arr = this.form.goodPhoto.map((item) => item.url);

          let data = {
            goodsName: this.form.goodsName, //商品名称
            goodstypeId: this.form.goodstypeId, //类型ID
            goodsDetails: this.form.goodsDetails, //商品详情
            ispayment: this.form.ispayment, //支付方式
            isDistribution: this.form.isDistribution, //分销售
            isPublic: this.form.isPublic, //上架
            goodPhoto: arr.join(","), //商品图片
            goodsId: this.goodsId,
            coverImg:
                this.form.coverImg && this.form.coverImg.length != 0
                    ? this.form.coverImg[0].url
                    : [],
            specType: this.form.goodsGG == 1 ? 'ONE' : 'MORE',
            qdJson:JSON.stringify(this.prodSize)
          };
          if (this.form.isPublic) {
            data.publicTypeId = this.form.type
          }

          if (this.form.goodsGG == 1) {
            data.specName = '默认'
            data.goodSpecRequestList = [{
              specName: '默认',
              list: [{
                specAttribute: '默认',
                specNum: this.form.stockNum,
                price: Math.round(this.form.price * 100)
              }]
            }]
          } else {
            if (this.prodSize.length == 1) {
              data.specName = this.prodSize[0].specName
            } else if (this.prodSize.length == 2) {
              data.specName = this.prodSize[0].specName
              data.specName2 = this.prodSize[1].specName
            } else if (this.prodSize.length == 2) {
              data.specName = this.prodSize[0].specName
              data.specName2 = this.prodSize[1].specName
              data.specName3 = this.prodSize[2].specName
            } else if (this.prodSize.length == 2) {
              data.specName = this.prodSize[0].specName
              data.specName2 = this.prodSize[1].specName
              data.specName3 = this.prodSize[2].specName
              data.specName4 = this.prodSize[3].specName
            }
            let gglist = JSON.parse(JSON.stringify(this.gglist))
            for (let i in gglist) {
              if (!gglist[i].img) {
                gglist[i].specGoodsImg = arr[0]
              }else {
                gglist[i].specGoodsImg = gglist[i].img
              }
              if (gglist[i].text0) {
                gglist[i]['specAttribute'] = gglist[i].text0
              }
              if (gglist[i].text1) {
                gglist[i]['specAttribute2'] = gglist[i].text1
              }
              if (gglist[i].text2) {
                gglist[i]['specAttribute3'] = gglist[i].text2
              }
              if (gglist[i].text3) {
                gglist[i]['specAttribute4'] = gglist[i].text3
              }
              if (gglist[i].price) {
                gglist[i].price = Math.round(gglist[i].price * 100)
              }
              if (gglist[i].inventory) {
                gglist[i].specNum = gglist[i].inventory
              }
            }

            data.goodSpecRequestList = [{
              list: gglist
            }]
          }
          if (this.$route.query.id) {
            addGoods(data)
                .then(() => {
                  this.$message({
                    message: "操作成功",
                    type: "success",
                    center: true,
                  });
                  this.$router.go(-1);
                })
                .catch(err=>{
                  this.$message({
                    message: err.message,
                    type: "success",
                    center: true,
                  });
                });
          } else {
            data.goodPhoto = arr.join(",")
            data.coverImg = this.form.coverImg[0].url
            addGoods(data).then(() => {
              this.$message({
                message: "操作成功",
                type: "success",
                center: true,
              });
              this.$router.go(-1);
            })
                .catch(err=>{
                  this.$message({
                    message: err.message,
                    type: "warning",
                    center: true,
                  });
                });
          }

          this.form.goodPhoto = [];
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    //分享图片
    shareUpload(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      let arr = [];
      fileList.map((item) => {
        if (item.response) {
          arr.push({
            url: `https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/${item.response.data}`,
            uid: item.uid,
          });
        } else {
          arr.push(item);
        }
      });
      this.form.coverImg = arr;
    },

    // //商品图片
    goodsUpload(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      let arr = [];
      fileList.map((item) => {
        if (item.response) {
          arr.push({
            url: `https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/${item.response.data}`,
            uid: item.uid,
          });
        } else {
          arr.push(item);
        }
      });
      this.form.goodPhoto = arr;
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    //新增分类弹窗
    open(row) {
      this.$prompt("请输入商品类型名称", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
      })
          .then( async ({ value }) => {
            await addGoodsType({
              name: value,
              sort: 0,
              isDel: 0,
            });
            const datas = await getGoodsTypeInfo({}); //获取分类数据
            this.goodsType = datas.data;
            this.$message({
              type: "success",
              message: "添加成功",
              center: true,
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "操作取消",
              center: true,
            });
          });
    },
    prodClose() {
      this.isShow = false;
    },
    handleRemove3(file, fileList, index) {
      this.gglist[index].img = ''
      this.gglist[index].fileList = []
      this.$forceUpdate()
    },
    handleSuccess(response, file, fileList, index) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      let url = `https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/${response.data}`
      this.gglist[index].img = url
      this.gglist[index].fileList = [{url, uid: 1}]
    },
    yulan(){
      this.isShow = true
      if (this.form.goodsGG == 1) {
        this.goodSpecRequestList = [{
          specName: '默认',
          list: [{
            specAttribute: '默认',
            specNum: this.form.stockNum,
            price: Math.round(this.form.price * 100)
          }]
        }]
      } else {
        this.goodSpecRequestList = this.prodSize
      }
    },
    beforeAvatarUploadImg(file) {
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1M!");
      }
      return isLt2M;
    },
  },
};
</script>
<style lang="scss" scope>
.disabled .el-upload--picture-card {
  display: none;
}
.enterprise_info {
  display: flex;
  flex-wrap: wrap;
}

.enterprise_item {
  background: #e0dede;
  padding: 0 10px;
  border-radius: 5px;
  color: #333333;
  margin: 0 10px 10px 0;
}

.ggNew_wrap {
  border: 1px solid #e4e4e4;
  width: 550px;
  border-radius: 5px;
  padding: 10px 20px;
}

.ggNew_wrap1 {
  border: 1px solid #e4e4e4;
  width: 700px;
  border-radius: 5px;
  padding: 10px 20px;
}

.gg_item {
  border: 2px dashed #EEEEEE;
  padding: 18px 14px;
  font-size: 14px;
  margin-bottom: 18px;
  position: relative;
}

.gg_item_del {
  display: flex;
  align-self: center;
  position: absolute;
  top: -6px;
  right: -6px;
  cursor: pointer;
}

.gg_item_del_img {
  width: 20px;
  height: 20px;
}

.ggdx {
  display: flex;
  align-self: center;
  padding-bottom: 5px;
}

.ggdx span:nth-child(1) {
  color: #fd634e;
  position: relative;
  padding-right: 8px;
}

.ggdx span:nth-child(2) {
  font-size: 14px;
  color: #444;
}

.ggdx span:nth-child(3) {
  font-size: 10px;
  color: #888;
  margin-left: 8px;
}

.ggdx_zhi {
  display: flex;
  justify-content: space-between;
}

.ggdx_zhi_input {
  font-size: 16px;
  padding-right: 12px;
}

.gg_list {
  display: flex;
  flex-wrap: wrap;
}

.gg_add {
  margin-right: 10px;
  padding: 0 10px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  border: 1px solid #54CCCA;
  border-radius: 29px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #54CCCA;
  position: relative;
  cursor: pointer;
}

.gg_add_del {
  display: flex;
  align-self: center;
  position: absolute;
  top: -6px;
  right: -6px;
  width: 16px;
  height: 16px;
}

.moreGG {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  border: 1px dashed #54CCCA;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #54CCCA;
}


.theadList {
  background: #F6F6F6;
  display: flex;
  justify-content: space-around;
  height: 28px;
  line-height: 28px;
}

.theadItem {
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #6C6C6C;
  display: flex;
  justify-content: center;
  white-space: nowrap;
}

.tbodyList {
  padding: 10px 0;
  display: flex;
  justify-content: space-around;
}

.xing {
  color: #fd634e;
  position: relative;
  padding-right: 4px;
}

.tbodyTitle {
  display: flex;
  align-self: center;
  justify-content: center;
}

.tbodyTitle > span {
  font-size: 12px;
  color: #333333;
}

.tbodyInput {
  font-size: 14px;
  color: #fd634e;
  text-align: center;
}

.tbodyInput1 {
  font-size: 14px;
  color: #383838;
  text-align: center;
}

input.tbodyInput::-webkit-input-placeholder {
  color: #fd634e;
  font-size: 14px;
}

input.tbodyInput::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fd634e;
  font-size: 14px;
}

input.tbodyInput:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fd634e;
  font-size: 14px;
}

input.tbodyInput:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fd634e;
  font-size: 14px;
}

.w28 {
  width: 28px;
}

.h28 {
  height: 28px;
}

.del_xm {
  width: 12px;
  height: 12px;
  position: absolute;
  top: -4px;
  right: -4px;
}

.disUoloadSty .el-upload--picture-card {
  display: none !important; /* 上传按钮隐藏 */
  height: 40px;
}
</style>
